import { variantProvider } from '@/app'
import { OptionsDropDown } from '@/components'
import { APIClient } from '@/services'
import { Dispute, Profile } from '@/types'
import { DisputeUtils } from '@/utils'
import { TypeGuards } from '@codeleap/common'
import { CSSObject } from '@emotion/react'

export type ParticipantOptionsProps = {
  dispute: Dispute
  participant: Profile
  onCopyEmail?: (participant: Profile) => void
  onViewInformation?: (participant: Profile) => void
  onRemove?: (participant: Profile) => void
  style: CSSObject
}

export function ParticipantOptions(props: ParticipantOptionsProps) {
  const { dispute, onCopyEmail, onViewInformation, onRemove, participant, ...rest } = props
  const { profile } = APIClient.Session.useSession()
  const { isMediator: isDisputeMediator } = DisputeUtils.getParticipantInfo(dispute, profile)
  const { isMediator, isOwner } = DisputeUtils.getParticipantInfo(dispute, participant)

  const options = [
    TypeGuards.isFunction(onCopyEmail) && {
      text: 'Copy email',
      onPress: () => onCopyEmail(participant),
      debugName: 'Copy email Option',
    },
    TypeGuards.isFunction(onViewInformation) && !isMediator && {
      text: 'View information',
      onPress: () => onViewInformation(participant),
      debugName: 'View information Option',
    },
    TypeGuards.isFunction(onRemove) && !isOwner && !isMediator && {
      text: 'Remove',
      onPress: () => onRemove(participant),
      debugName: 'Remove Option',
      variants: ['destructive:minimal'] as any,
    },
  ]

  if (!isDisputeMediator) return null
  return (
    <OptionsDropDown
      {...rest}
      icon={'more-horizontal'}
      styles={{ icon: styles.icon }}
      debugName='Options DropDown'
      options={options.filter(Boolean)}
    />
  )
}

const styles = variantProvider.createComponentStyle(
  theme => ({
    icon: {
      color: theme.colors.primary3,
    },
  }),
  true,
)

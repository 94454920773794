import { variantProvider } from '../theme'
import { PageSectionComposition } from './PageSection'
import { assignTextStyle } from './Text'

type BannerComposition = 'wrapper' | 'icon' | 'text'

export type ChatComposition =
 | 'wrapper'
 | 'innerWrapper'
 | `pageSection${Capitalize<PageSectionComposition>}`
 | `banner${Capitalize<BannerComposition>}`

const createChatStyle = variantProvider.createVariantFactory<ChatComposition>()

export const ChatStyles = {
  default: createChatStyle((theme) => ({
    pageSectionWrapper: {
      background: 'linear-gradient(180deg, #F6F6F6 0%,  #FFFFFF 100%)',
    },
    pageSectionContentWrapper: {
      ...theme.spacing.paddingTop(0.25),
      ...theme.spacing.paddingBottom(2),
    },
    wrapper: {
      ...theme.presets.flex,
      ...theme.presets.relative,
      ...theme.presets.column,
      ...theme.presets.justifyEnd,
      height: 'calc(100vh - 203px)',
      [theme.media.down('midish')]: {
        height: 'calc(100vh - 149px)',
      },
      [theme.media.down('smallish')]: {
        height: 'calc(100vh - 107px)',
      },

    },
    innerWrapper: {
      flex: 1,
      ...theme.spacing.gap(0.5),
      ...theme.presets.fullWidth,
      ...theme.presets.column,
      overflowY: 'auto',
      overflowX: 'hidden',
      '&::-webkit-scrollbar': { width: 0 },
    },

    //Banner styles
    bannerWrapper: {
      ...theme.spacing.padding(2),
      ...theme.spacing.marginTop(1.75),
      ...theme.spacing.gap(2),
      backgroundColor: theme.colors.neutral3,
      borderRadius: theme.borderRadius.small,
      ...theme.spacing.marginBottom('auto'),
    },
    bannerIcon: {
      ...theme.sized(2.5),
    },
    bannerText: {
      ...assignTextStyle('p3')(theme).text,
      ...theme.presets.flex,
    },
  })),
}

import { ActionIcon, OSAlert, View } from '@/components'
import { AnyFunction } from '@codeleap/common'
import { stopPropagation, ViewProps } from '@codeleap/web'

export function CopyEmail({ onCopyEmail, ...props }: ViewProps<'div'> & { onCopyEmail: AnyFunction}) {
  const onPress = () => {
    onCopyEmail()
    OSAlert.info({ title: '', body: 'Email copied to clipboard' })
  }

  return (
    // @ts-ignore
    <View onClick={stopPropagation} {...props}>
      <ActionIcon
        debugName='CopyEmail action icon'
        icon='copy'
        onPress={onPress}
        title='Copy email'
        variants={['primary:outline', 'copyEmail']}
      />
    </View>
  )
}

import React, { useCallback, useMemo, useRef } from 'react'
import { ChatStyles } from '@/app/stylesheets'
import { ChatComposition } from '@/app/stylesheets/Chat'
import {
  ChatMessage,
  Icon,
  PageSection,
  Placeholder,
  Scroll,
  ToolBar,
  View,
  Text,
} from '@/components'
import { APIClient } from '@/services'
import { throttle } from 'lodash'
import { Dispute, DisputeMessage, DisputeThread } from '@/types'
import { ComponentVariants, onUpdate, StylesOf, TypeGuards, useDefaultComponentStyle, usePrevious } from '@codeleap/common'
import { ChatUtils, MiscUtils, Navigation, useGetStyles, useInterval, useScrollRef } from '@/utils'
import { I18N } from '@/app'

type ChatProps = ComponentVariants<typeof ChatStyles> & {
  styles?: StylesOf<ChatComposition>
  style?: StyleSheet
  dispute?: Dispute
  threadId?: DisputeThread['id']
  threads?: DisputeThread[]
}

function Banner({ styles, text }) {
  return (
    <View css={styles.wrapper}>
      <Icon name='lock' style={styles.icon} debugName='banner icon' />
      <Text variant={`p1`} text={text} css={styles.text} />
    </View>
  )
}

export function Chat({ dispute, threadId, threads, ...props }: ChatProps) {
  const { variants, styles, responsiveVariants } = props

  const { scrollRef, scrollToBottom, handleScrollToTop } = useScrollRef()
  const previousThreadId = usePrevious(threadId)
  const shouldScrollRef = useRef(true)
  const userHasScrolledRef = useRef(false)

  const { isMediator, profile } = APIClient.Session.useSession()
  const { create } = APIClient.Threads.messagesManager.useCreate({ onListsWithFilters: threadId, optimistic: true })
  const { loadMoreMessages, loadNewMessages, messagesArray } = APIClient.Threads.useMessages(threadId)

  const variantStyles = useDefaultComponentStyle<'u:Chat', typeof ChatStyles>('u:Chat', {
    variants,
    styles,
    responsiveVariants,
  })

  const { getStyles } = useGetStyles(variantStyles)

  const { hasAiMessage, thread, placeholderText } = useMemo(() => {
    const thread = threads?.find(t => t.id === threadId)
    const hasAiMessage = TypeGuards.isString(thread.mediator_message) && !!thread.mediator_message.trim() && isMediator

    return {
      hasAiMessage,
      thread,
      placeholderText: I18N.t(`Chat.placeholder.${thread.type === 'all' ? 'all' : 'withMediator'}`),
    }
  }, [threads, threadId, I18N.locale])

  useInterval(loadNewMessages, 5000)

  onUpdate(() => {
    if (previousThreadId !== threadId) {
      scrollToBottom()
      userHasScrolledRef.current = false
      shouldScrollRef.current = true
    }

    if (messagesArray.length > 0 && shouldScrollRef.current) {
      scrollToBottom()
      shouldScrollRef.current = false
    }
  }, [messagesArray, threadId])

  const sendMessage = useCallback(async (content) => {
    if (content.trim()) {
      scrollToBottom()
      await create({ content, thread: threadId, created_datetime: new Date(), owner: profile })
      await loadNewMessages()
      shouldScrollRef.current = true
    }
  }, [threadId])

  const renderItem = useCallback((message: DisputeMessage, index: number, array: DisputeMessage[]) => (
    <ChatMessage
      key={message.id}
      message={message}
      dispute={dispute}
      nextMessage={array[index + 1] ?? null}
      prevMessage={array[index - 1] ?? null}
      {...MiscUtils.getPositionProps(index, array.length)}
    />
  ), [dispute])

  const handleScroll = throttle(() => {
    if (!userHasScrolledRef.current) {
      userHasScrolledRef.current = true
      return
    }
    handleScrollToTop(loadMoreMessages, 100)
  }, 500)

  return (
    <PageSection
      title={ChatUtils.chatTitles({ dispute, thread: thread.type, isMediator })}
      styles={getStyles('pageSection')}
      onBack={() => Navigation.goToDispute({ id: dispute.id })}
    >
      <View css={getStyles('wrapper')}>
        <Scroll css={getStyles('innerWrapper')} onScroll={handleScroll} ref={scrollRef}>
          {messagesArray.length > 0 ? (
            <>
              <Banner styles={getStyles('banner')} text={placeholderText} />
              {messagesArray.map(renderItem)}
            </>
          ) : (
            <Placeholder variants={['compact']} icon={'chat' as any} text={placeholderText} />
          )}
        </Scroll>
        <ToolBar
          disabled={['pending', 'closed'].includes(dispute?.status)}
          styles={getStyles('toolbar')}
          onSend={sendMessage}
          aiMessage={hasAiMessage ? thread.mediator_message : null}
          hasAiMessage={hasAiMessage}
        />
      </View>
    </PageSection>
  )
}


import { assignTextStyle, AvatarComposition } from '@codeleap/common'
import { TagComposition } from '@codeleap/web'
import { variantProvider } from '../theme'

export type ParticipantItemComposition =
  | 'wrapper'
  | 'wrapper:selected'
  | 'innerWrapper'
  | 'rightWrapper'
  | `avatar${Capitalize<AvatarComposition>}`
  | 'avatarImage'
  | 'avatarInitials'
  | 'name'
  | `tag${Capitalize<TagComposition>}`
  | 'email'
  | 'options'
  | 'checkbox'

const createParticipantItemStyle =
  variantProvider.createVariantFactory<ParticipantItemComposition>()

export const ParticipantItemStyles = {
  default: createParticipantItemStyle((theme) => ({
    wrapper: {
      ...theme.presets.fullWidth,
      backgroundColor: theme.colors.neutral1,
      ...theme.spacing.padding(2),
      ...theme.border.neutral3({ width: 1, directions: ['bottom'] }),

      '&:first-of-type': {
        borderTopLeftRadius: theme.borderRadius.small,
        borderTopRightRadius: theme.borderRadius.small,
      },

      '&:last-of-type': {
        border: 'none',
        borderBottomLeftRadius: theme.borderRadius.small,
        borderBottomRightRadius: theme.borderRadius.small,
      },
    },
    'wrapper:selected': {
      backgroundColor: theme.colors.primary2,
    },
    innerWrapper: {
      ...theme.presets.fullWidth,
      ...theme.presets.alignCenter,
      ...theme.spacing.gap(2),
      display: 'grid',
      gridTemplateAreas: `
        'avatar name tag email options'
      `,
      gridTemplateColumns: 'auto 1fr auto auto auto',
      [theme.media.down('largeish')]: {
        ...theme.spacing.gap('4px 8px'),
        gridTemplateAreas: `
          'name tag options'
          'email email email'
        `,
        gridTemplateColumns: '1fr auto auto',
      },
    },

    //Avatar
    avatarWrapper: {
      gridArea: 'avatar',
      ...theme.sized(4),

      [theme.media.down('largeish')]: {
        display: 'none',
      },
    },
    avatarImage: {
      ...theme.sized(4),
    },

    //Tag
    tagWrapper: {
      gridArea: 'tag',
      backgroundColor: theme.colors.neutral2,
      [theme.media.down('largeish')]: {
        ...theme.spacing.paddingVertical(0.5),
        height: 24,
      },
    },
    tagText: {
      whiteSpace: 'nowrap',
      ...assignTextStyle('p3')(theme).text,
      lineHeight: '16px',
    },

    name: {
      ...theme.presets.fullWidth,
      gridArea: 'name',
    },
    email: {
      gridArea: 'email',
      ...assignTextStyle('p3')(theme).text,
      color: theme.colors.neutral7,
      lineHeight: '16px',
    },
    options: {
      gridArea: 'options',
    },
    checkbox: {
      gridArea: 'checkbox',
    },
  })),
  preview: createParticipantItemStyle((theme) => ({
    wrapper: {
      borderBottom: 0,
      ...theme.border.neutral3({
        directions: ['top'],
        width: theme.values.borderWidth.small,
      }),
    },
    tagRole: {
      ...theme.presets.hidden,
    },
    email: {
      ...theme.presets.hidden,
    },
    iconTouchableWrapper: {
      ...theme.presets.hidden,
    },
    previewIconTouchableWrapper: {
      display: 'flex',
    },
  })),
  requestSignatures: createParticipantItemStyle((theme) => ({
    wrapper: {
      '&:first-of-type': {
        borderTopLeftRadius: 'none',
        borderTopRightRadius: 'none',
      },

      '&:last-of-type': {
        borderBottomLeftRadius: 'none',
        borderBottomRightRadius: 'none',
      },
    },
    innerWrapper: {
      gridTemplateAreas: `
        'checkbox avatar name tag email options'
      `,
      gridTemplateColumns: 'auto auto 1fr auto auto auto',

      [theme.media.down('largeish')]: {
        ...theme.spacing.gap(2),
        gridTemplateAreas: `
          'checkbox avatar name tag email options'
        `,
        gridTemplateColumns: 'auto auto 1fr auto auto auto',
      },

      [theme.media.down('small')]: {
        ...theme.spacing.gap('6px 8px'),
        gridTemplateAreas: `
          'checkbox avatar name options'
          'checkbox avatar tag options'
          'checkbox avatar email options'
        `,
        gridTemplateColumns: 'auto auto 1fr auto',
      },
    },

    avatarWrapper: {
      [theme.media.down('largeish')]: {
        display: 'block',
      },
    },
    tagWrapper: {
      [theme.media.down('largeish')]: {
        ...theme.spacing.padding(1),
        height: 32,
      },
    },

    checkbox: {
      display: 'block',
    },
    email: {
      [theme.media.down('largeish')]: {
        ...theme.presets.fullWidth,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  })),
  signaturesRequested: createParticipantItemStyle((theme) => ({
    wrapper: {
      '&:first-of-type': {
        borderTopLeftRadius: 'none',
        borderTopRightRadius: 'none',
      },

      '&:last-of-type': {
        borderBottomLeftRadius: 'none',
        borderBottomRightRadius: 'none',
      },
    },
    innerWrapper: {
      [theme.media.down('largeish')]: {
        ...theme.spacing.gap(2),
        gridTemplateAreas: `
            'avatar name tag email options'
          `,
        gridTemplateColumns: 'auto 1fr auto auto auto',
      },

      [theme.media.down('small')]: {
        ...theme.spacing.gap('6px 8px'),
        gridTemplateAreas: `
            'avatar name options'
            'avatar email options'
            'avatar tag options'
          `,
        gridTemplateColumns: 'auto 1fr auto',
      },
    },

    avatarWrapper: {
      [theme.media.down('largeish')]: {
        display: 'block',
      },

      [theme.media.down('small')]: {
        ...theme.presets.alignSelfStart,
      },
    },
    tagWrapper: {
      [theme.media.down('largeish')]: {
        ...theme.spacing.padding(1),
        height: 32,
      },
    },

    email: {
      width: 250,
      ...theme.presets.textRight,

      [theme.media.down('small')]: {
        ...theme.presets.fullWidth,
        ...theme.presets.textLeft,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },

    options: {
      [theme.media.down('small')]: {
        ...theme.presets.alignSelfStart,
      },
    },
  })),
}

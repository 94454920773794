import { Theme } from '@/app'
import { SettlementItemStyles } from '@/app/stylesheets'
import { SettlementItemComposition } from '@/app/stylesheets/SettlementItem'
import { View, Text, ActionIcon, Icon, SettlementOptions } from '@/components'
import { APIClient } from '@/services'
import { DisputeSettlement } from '@/types'
import { FileUtils, DateUtils, useGetStyles } from '@/utils'
import { AnyFunction, ComponentVariants, StylesOf, useDefaultComponentStyle } from '@codeleap/common'
import { useMediaQuery } from '@codeleap/web'

export type SettlementItemProps = ComponentVariants<typeof SettlementItemStyles> & {
  styles?: StylesOf<SettlementItemComposition>
  style?: StyleSheet
  settlement?: Partial<DisputeSettlement>
  isLast?: boolean
  isFirst?: boolean
  isOnly?: boolean
  onDelete?: AnyFunction
  onMarkAsReady?: AnyFunction
  onDownload?: AnyFunction
  onEditContext?: AnyFunction
  hideOptions?: boolean
  footer?: JSX.Element
}

export const SettlementItem = ({
  settlement,
  onDelete,
  onMarkAsReady,
  onDownload,
  onEditContext,
  footer,
  ...props
}: SettlementItemProps) => {
  const { variants, styles, responsiveVariants, style, isLast, isFirst, isOnly, hideOptions, ...rest } = props
  const { file, created_datetime } = settlement

  const downBreakpoint = useMediaQuery(Theme.media.down('largeish'))
  const { isMediator } = APIClient.Session.useSession()

  const variantStyles = useDefaultComponentStyle<'u:SettlementItem', typeof SettlementItemStyles>('u:SettlementItem', {
    variants,
    rootElement: 'wrapper',
    styles,
    responsiveVariants,
  })

  const { getStyles: _getStyles } = useGetStyles(variantStyles)
  const getStyles = key => _getStyles(key, [isFirst && 'isFirst', isLast && 'isLast', isOnly && 'isOnly'])

  return (
    <View {...rest} css={[getStyles('wrapper'), style]}>
      <View css={[variantStyles.innerWrapper]}>
        <View css={[variantStyles.leftContent]}>
          <Icon name='file' variants={['medium', 'neutral7']} css={getStyles('leftIcon')} debugName='file icon' />
          <Text text={FileUtils.formatFileName(file as string)} css={[variantStyles.name]} />
        </View>
        {created_datetime && <Text text={DateUtils.formatDate(created_datetime)} css={[variantStyles.date]} />}
        {hideOptions || (isMediator && downBreakpoint) ? null : (
          <ActionIcon
            name='download'
            variants={['minimal', 'neutral10']}
            styles={getStyles('rightIcon')}
            onPress={onDownload}
            debugName={'Settlement Icon'}
          />
        )}
        {!hideOptions && isMediator ? (
          <SettlementOptions onDelete={onDelete} onMarkAsReady={onMarkAsReady} onDownload={onDownload} onEditContext={onEditContext} />
        ) : null}
        {footer}
      </View>
    </View>
  )
}

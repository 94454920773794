import { AnyFunction, TypeGuards } from '@codeleap/common'
import { I18N } from '@/app'
import { AppStatus } from '@/redux'

// NOTE This is the file were you put functions you don't know where else to put

export const humanizeSnakeCase = (snakeCase: string) => {
  if (!snakeCase) return ''
  const newString = snakeCase.split('_').join(' ')
  return `${newString[0].toUpperCase()}${newString.slice(1)}`
}

export const hasValue = (...args) => (
  args.every((arg) => !TypeGuards.isNil(arg))
)

const companyStrenghts = [
  { type: I18N.t('Homepage.strenghts.fair'), icon: 'scale', description: I18N.t('Homepage.strenghts.fairDescription') },
  { type: I18N.t('Homepage.strenghts.relaxed'), icon: 'rocking-chair', description: I18N.t('Homepage.strenghts.relaxedDescription') },
  { type: I18N.t('Homepage.strenghts.fast'), icon: 'timer', description: I18N.t('Homepage.strenghts.fastDescription') },
  { type: I18N.t('Homepage.strenghts.convenient'), icon: 'calendar-clock', description: I18N.t('Homepage.strenghts.convenientDescription') },
  { type: I18N.t('Homepage.strenghts.affordable'), icon: 'coins', description: I18N.t('Homepage.strenghts.affordableDescription') },
  { type: I18N.t('Homepage.strenghts.digital'), icon: 'mouse-pointer-click', description: I18N.t('Homepage.strenghts.digitalDescription') },
]

const capitalizeFirstChar = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function formatPrice(price) {
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const asyncHandler = async (callback: AnyFunction) => {
  AppStatus.set('loading')
  try {
    await callback()
  } catch (error) {
    console.log(error)
  }
  AppStatus.set('idle')
}

function getPositionProps(index, length) {
  return {
    isFirst: index === 0,
    isLast: index === length - 1,
    isOnly: length === 1,
  }
}

export const MiscUtils = {
  companyStrenghts,
  capitalizeFirstChar,
  formatPrice,
  asyncHandler,
  getPositionProps,
}


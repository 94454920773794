import { ActivityIndicator, MainLayout, MainLayoutProps, DisputeInfo, View } from '@/components'
import { cloneElement, PropsWithChildren } from 'react'
import { Dispute } from '@/types'
import { APIClient } from '@/services'
import { useMediaQueryDown } from '@/utils'

type DisputeLayoutProps = MainLayoutProps &
  PropsWithChildren & {
    id: Dispute['id']
  }

export const DisputeLayout = ({ id, children, ...props }: DisputeLayoutProps) => {
  const isMidsh = useMediaQueryDown('midish')

  const { items: threads } = APIClient.Threads.threadsManager.useList({
    filter: { dispute: id },
    queryOptions: { refetchInterval: 10000 },
  })
  const { data: dispute } = APIClient.Disputes.disputesManager.useRetrieve({
    id,
    queryOptions: { refetchInterval: 10000 },
  })

  const isLoaded = threads?.length > 0 && !!dispute

  if (!isLoaded) return <ActivityIndicator debugName='Loading DisputeDetails' variants={['margin:auto']} />
  return (
    <MainLayout {...props}>
      <View variants={['fullWidth']} responsiveVariants={{ small: ['block'] }}>
        {(!isMidsh || !children) && <DisputeInfo dispute={dispute} threads={threads} />}
        {!!children && cloneElement(children as JSX.Element, { dispute, threads })}
      </View>
    </MainLayout>
  )
}

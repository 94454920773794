import { assignTextStyle, variantProvider } from '@/app'
import { ParticipantItemStyles } from '@/app/stylesheets'
import { ParticipantItemComposition } from '@/app/stylesheets/ParticipantItem'
import {
  Avatar,
  View,
  Touchable,
  Text,
  Tag,
  ParticipantOptions,
  ParticipantOptionsProps,
} from '@/components'
import { Dispute, Profile } from '@/types'
import { DisputeUtils, useGetStyles } from '@/utils'
import { AnyFunction, ComponentVariants, PropsOf, StylesOf, TypeGuards, useDefaultComponentStyle } from '@codeleap/common'
import { useMemo } from 'react'
import { SelectParticipant } from './SelectParticipant'
import { CopyEmail } from './CopyEmail'

export type ParticipantItemProps = ComponentVariants<typeof ParticipantItemStyles> &
  ParticipantOptionsProps &
  Omit<PropsOf<typeof View>, 'styles' | 'variants'> &
  Omit<PropsOf<typeof Touchable>, 'styles' | 'variants'> & {
    styles?: StylesOf<ParticipantItemComposition>
    participant: Profile
    dispute: Dispute
    showTag?: boolean
    checkboxValue?: string
    onCheckboxChange?: (value: string) => void
    copyEmail: AnyFunction
    showOptions?: boolean
    tagText?: string
  }

export const ParticipantItem = ({
  variants = [],
  styles = {},
  style,
  responsiveVariants = {},
  participant,
  dispute,
  tagText,

  onPress = null,
  onViewInformation,
  onRemove,
  onCopyEmail,

  showTag = true,
  showOptions = false,

  checkboxValue,
  onCheckboxChange,

  ...otherProps
}: ParticipantItemProps) => {
  const variantStyles = useDefaultComponentStyle<'u:ParticipantItem', typeof ParticipantItemStyles>(
    'u:ParticipantItem',
    {
      variants,
      rootElement: 'wrapper',
      styles,
      responsiveVariants,
    },
  )

  const { getStyles } = useGetStyles(variantStyles)

  const optionsProps = { onCopyEmail, onViewInformation, onRemove, dispute, participant, style: variantStyles.options }
  const checkboxProps = {
    showCheckbox: TypeGuards.isFunction(onCheckboxChange),
    value: checkboxValue === participant.id,
    onValueChange: () => onCheckboxChange(participant.id),
    style: variantStyles.checkbox,
  }

  const { name, isSelected } = useMemo(() => ({
    name: participant?.first_name ?? participant?.full_name,
    isSelected: checkboxValue === participant?.id,
  }), [dispute, participant, checkboxValue])

  const { Wrapper, wrapperProps } = useMemo(() => {
    const isPressable = TypeGuards.isFunction(onPress)
    return { Wrapper: isPressable ? Touchable : View, wrapperProps: isPressable ? { onPress } : {}}
  }, [onPress])

  return (
    <Wrapper css={[getStyles('wrapper', [isSelected && ':selected']), style]} {...wrapperProps} {...otherProps}>
      <View css={[variantStyles.innerWrapper]}>
        <SelectParticipant {...checkboxProps} />
        <Avatar debugName='Participant Avatar' name={name} styles={getStyles('avatar')} />
        <Text text={name} css={[variantStyles.name]} />
        {showTag ? <Tag debugName='Tag Role' text={tagText} styles={getStyles('tag')} /> : null}
        <Text text={participant?.email} css={[variantStyles.email]} />
        {showOptions ? <ParticipantOptions {...optionsProps} /> : <CopyEmail {...optionsProps} />}
      </View>
    </Wrapper>
  )
}

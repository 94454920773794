import { Checkbox } from '@/components'
import { CheckboxProps } from '@codeleap/web'

type SelectParticipantProps = Omit<CheckboxProps, 'debugName'> & {
  showCheckbox?: boolean
}

export function SelectParticipant({ showCheckbox, ...props }: SelectParticipantProps) {

  if (!showCheckbox) return null
  // @ts-ignore
  return <Checkbox debugName='Select Participant' variants={'noError'} {...props}/>
}

import { Dispute, Profile } from '@/types'
import { ParticipantInviteModal } from './ParticipantInviteModal'
import { View, PageSection, Button, Alert } from '@/components'
import { APIClient } from '@/services'
import { AppStatus } from '@/redux'
import { Navigation, useMediaQueryDown, useParticipantList, useRoleInDispute } from '@/utils'
import { useBooleanToggle, useState } from '@codeleap/common'

export type ParticipantPageSectionProps = {
  dispute?: Dispute
}

export const ParticipantPageSection = ({ dispute }: ParticipantPageSectionProps) => {
  const isMobile = useMediaQueryDown('smallish')
  const { isMediator } = useRoleInDispute(dispute)
  const [selectedParticipant, setSelectedParticipant] = useState<Profile>()
  const [visible, toggle] = useBooleanToggle(false)
  const [removeAlert, toggleRemoveAlert] = useBooleanToggle(false)
  const { FirstPartyParticipants, SecondPartyParticipants, MediatorParticipant } = useParticipantList(dispute)

  const handleRemove = (participant: Profile) => {
    setSelectedParticipant(participant)
    toggleRemoveAlert()
  }

  const removeParticipant = () => {
    if (selectedParticipant) {
      AppStatus.set('loading')
      toggleRemoveAlert()
      APIClient.Disputes.removeParticipant({
        profile: selectedParticipant.id,
        dispute: dispute.id,
      })
        .then(() => APIClient.Disputes.disputesManager.queryClient.invalidateQueries())
        .finally(() => AppStatus.set('idle'))
    }
  }

  return (
    <PageSection
      title='Participants'
      onBack={() => Navigation.goToDispute({ id: dispute.id })}
      headerRightComponent={
        isMediator ? (
          <Button
            debugName='ParticipantPageSection:Invite'
            icon='invite'
            text='Invite'
            variants={['primary:outline', 'small', isMobile ? 'pageSectionMobile' : '']}
            onPress={toggle}
          />
        ) : null
      }
    >
      <View variants={['column', 'flex']}>
        <FirstPartyParticipants onRemove={handleRemove} showOptions={isMediator}/>
        <SecondPartyParticipants onRemove={handleRemove} showOptions={isMediator}/>
        <MediatorParticipant onRemove={handleRemove} showOptions={isMediator}/>
      </View>

      <ParticipantInviteModal dispute={dispute} debugName='ParticipantInviteModal' visible={visible} toggle={toggle} />

      <Alert
        optionsOnly
        toggle={toggleRemoveAlert}
        visible={removeAlert}
        debugName='RemoveParticipantAlert'
        type='destructive'
        title='Remove Participant'
        body='Are you sure you want to remove PARTICIPANT from this case?
This is a permanent action and cannot be undone, do you want to proceed?'
        options={[
          {
            debugName: 'RemoveParticipant:Cancel',
            text: 'Cancel',
            onPress: toggleRemoveAlert,
            variants: ['outline'] as any,
          },
          {
            debugName: 'RemoveParticipant:Remove',
            text: 'Remove',
            onPress: removeParticipant,
            variants: ['destructive'] as any,
          },
        ]}
      />
    </PageSection>
  )
}

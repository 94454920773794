import { Text, TextInput, View } from '@/components'
import { useIsMobile } from '@/utils'
import { TextInputProps } from '@codeleap/web'

type ContextProps = Omit<TextInputProps, 'debugName'> & {
  form: any
  hasError: boolean
  error: string
  height: number | string
}

export function Context(props: ContextProps) {
  const isMobile = useIsMobile()
  const { form, hasError, error, height = isMobile ? '30vh' : '40vh' } = props

  return (
    <View variants={['column']}>
      <TextInput
        {...form.register('context')}
        multiline
        variants={['noError', 'settlementContext']}
        style={{ minHeight: height, maxHeight: height }}
        _error={hasError ? 'e' : null}
      />
      <Text variants={['p4', hasError ? 'color:destructive2' : '']} text={error} />
    </View>
  )
}

import { createSettings } from '@codeleap/common'
import Obfuscate from './Obfuscate'
import * as Sentry from '@sentry/gatsby'

export const IsDevEnv = process.env.NODE_ENV === 'development'

const enableSentry = !IsDevEnv

const ENV = IsDevEnv ? 'development' : 'production'

export const GATSBY_DEV_ENV = process.env.GATSBY_DEVELOPMENT_ENVIRONMENT === 'true'

/** Change this to true if you want to test with a local server */
const LOCAL_TEST = false

const _Settings = createSettings({
  AppName: 'MediationX',
  CompanyName: 'Example Ltd.',
  Description: 'This is a template',
  Environment: {
    IsDev: IsDevEnv,
    Type: ENV,
    InitTime: new Date(),
  },
  Images: {
    Aspect: {
      default: 1,
    },
  },
  Application: {
    IsBrowser: false,
  },
  PerformanceInspector: {
    enable: true,
    maxRenders: 30,
  },
  Website: {
    ProductionURL: 'https://mediationx.com/',
    DevelopmentURL: 'https://mediationx.codeleap.co.uk/',
  },
  Fetch: {
    ProductionURL: LOCAL_TEST
      ? 'http://127.0.0.1:8000/'
      : 'https://api-mediationx.codeleap.co.uk/',
    DevelopmentURL: LOCAL_TEST
      ? 'http://127.0.0.1:8000/'
      : 'https://api-mediationx.codeleap.co.uk/',
    ViewHTMLErrors: true,
  },
  Social: {
    FacebookURL: 'https://www.facebook.com/codeleapuk/',
    LinkedinURL: 'https://www.linkedin.com/company/codeleap-uk',
    Instagram: 'https://www.facebook.com/codeleapuk/',
  },
  ContactINFO: {
    Website: 'codeleap.co.uk',
    TermsAndConditions: '/terms_and_conditions/?section=terms',
    PrivacyPolicy: '/terms_and_conditions/?section=privacy',
    TermsAgreement: '/terms_and_conditions/?section=agreement',
    MediationXTerms: 'https://docs.google.com/document/d/14Gkr2gkrz3eCOvV-4b_JmnCuhInUsG_DpGWT3_jU9gE/edit#bookmark=kix.uayg7oh29goa',
    SupportEMAIL: 'support@mediationx.com',
    ContactPHONE: '+44 (0) 333 050 9420',
  },
  Logger: {
    Level: 'debug',
    // TODO - get device or browser id
    DeviceIdentifier: '',
    StringifyObjects: true,
    IgnoreWarnings: [
      `[react-native-gesture-handler] Seems like you're using`,
      `Require cycle:`,
      `Require cycles are allowed`,
      `Running `,
      `WARN  Require cycle`,
      ` Warning: Failed`,
      `Warning: Failed`,
      'new NativeEventEmitter',
      'User cancelled',
      'React does not recognize the',
      'Unknown event handler property',
      'forwardRef render functions accept exactly ',
    ],
    Obfuscate,
    ComponentLogging: true,
  },
  Sentry: {
    enable: enableSentry,
    provider: Sentry,
    initArgs: {
      enabled: enableSentry,
      environment: ENV,
    },
    dsn: 'https://7a3025f25a19304ce779ab2862d9fc2a@o309198.ingest.sentry.io/4506343373209600',
  },
  Slack: {
    echo: {
      icon: 'https://avatars.githubusercontent.com/u/48894125?s=200&v=4',
      token: 'xoxb-622265672359-1248324007429-Ts31vPT8jCNh7L99xtdbOgQB',
      channel: '#_dev_logs',
    },
  },
  ApiCredentials: {
    GoogleSignin: {
      WebClientId:
        '268760770384-ob7jfjntuorgqaj2tt2mvnqdokd634et.apps.googleusercontent.com',
    },
    AppleSignIn: {
      ServiceClientId: '',
      RedirectURI: '',
    },
    FacebookSDK: {
      AppId: '1130448934268035',
    },
  },
  EnableAnalytics: !!process.env.GATSBY_GA_MEASUREMENT_ID && !IsDevEnv && !GATSBY_DEV_ENV,
})

if (_Settings.Environment.IsDev) {
  // TODO read environment
  // _Settings = deepMerge(_Settings, env)
}

if (!_Settings.ContactINFO.TermsAndConditions) {
  _Settings.ContactINFO.TermsAndConditions = `https://codeleap.co.uk/dev/policies/?app=${Buffer.from(
    _Settings.AppName,
    'base64',
  )}`
}

export const Settings = _Settings

import { ActionIcon, Text, View } from '@/components'
import { useGetStyles } from '@/utils'
import { UploadSettlementProps } from '.'
import { ActionIconProps } from '@codeleap/web'

type HeaderProps = Pick<ActionIconProps, 'onPress'> & {
  styles: UploadSettlementProps['styles']
}

export function Header({ styles, ...props }: HeaderProps) {
  const { getStyles } = useGetStyles(styles)

  return (
    <View css={styles.headerWrapper}>
      <Text css={styles.headerTitle} text={'Upload Document'} />
      <ActionIcon
        debugName={'Icon close modal'}
        name={'x'}
        styles={getStyles('headerActionIcon')}
        variants={['iconSize:3', 'minimal', 'neutral10']}
        {...props}
      />
    </View>
  )
}

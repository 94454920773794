import { variantProvider } from '../theme'

export type SettlementListComposition = 'wrapper' | 'header' | 'emptyWrapper' | 'iconWrapper' | 'icon'

const createSettlementListStyle = variantProvider.createVariantFactory<SettlementListComposition>()

export const SettlementListStyles = {
  default: createSettlementListStyle(theme => ({
    wrapper: {
      ...theme.presets.fullWidth,
      ...theme.presets.column,
      ...theme.presets.flex,
      flex: 1,
      ...theme.presets.fullHeight,
    },
  })),
}

import { ParticipantsList } from '@/components'
import { AppStatus } from '@/redux'
import { APIClient } from '@/services'
import { Dispute, Profile } from '@/types'
import { DisputeUtils } from '../disputes'

type ParticipantListOptions = {
  showCopyEmailAction?: boolean
  showOnlySignatories?: boolean
  showParticipantRole?: ('Legal Rep' | 'Case Creator' | 'Mediator' | 'Participant' | 'Second Party')[]
}

export function useParticipantList(dispute: Dispute, options: ParticipantListOptions = {}) {
  const { showCopyEmailAction = false, showOnlySignatories = false, showParticipantRole = [] } = options
  const { quizzes } = APIClient.Disputes.useQuizzes({ dispute: dispute?.id })

  const {
    first_parties = [],
    second_parties = [],
    first_parties_legal_reps = [],
    second_parties_legal_reps = [],
  } = dispute ?? {}

  const partyParticipants = {
    first: [...first_parties, ...first_parties_legal_reps],
    second: [...second_parties, ...second_parties_legal_reps],
    mediator: [dispute?.mediator],
  }

  const copyEmail = participant => navigator.clipboard.writeText(participant?.email)

  const handleViewInformation = (participant: Profile) => {
    const quiz = Object.values(quizzes?.itemMap).find(q => q.owner.id === participant?.id && q.dispute === dispute?.id)

    AppStatus.setModal(['participantInfo', true, { participant, dispute, quiz }])
  }

  const rootProps = party => {
    const participants = showOnlySignatories ? [dispute?.[`${party}_party_info`]] : partyParticipants[party]
    const title = party === 'first' ? 'First party' : party === 'second' ? 'Second party' : 'Mediator'

    return {
      title,
      participants,
      participantItemProps: participant => {
        const roleText = DisputeUtils.getParticipantRoleName(dispute, participant)

        return {
          showTag: !showParticipantRole.length || showParticipantRole.includes(roleText),
          tagText: roleText,
          onCopyEmail: copyEmail,
          onViewInformation: handleViewInformation,
          dispute,
          copyEmail,
          handleViewInformation,
        }
      },
    }
  }

  return {
    FirstPartyParticipants: props => <ParticipantsList {...rootProps('first')} {...props} />,
    SecondPartyParticipants: props => <ParticipantsList {...rootProps('second')} {...props} />,
    MediatorParticipant: props => <ParticipantsList {...rootProps('mediator')} {...props} />,
  }
}

import { View, Thread, Text, Button, Tag, DisputeStatusTag, DisputeSelectStatus } from '@/components'
import { ComponentVariants, TypeGuards, useCallback, useDefaultComponentStyle, useMemo } from '@codeleap/common'
import { Dispute, DisputeThread } from '@/types'
import { DisputeInfoStyles } from '@/app/stylesheets'
import { DisputeCategoryTag } from './DisputeCategoryTag'
import { applyTextMaxLines, DateUtils, DisputeUtils, formatPrice, MiscUtils, Navigation, useGetStyles, useSettlements } from '@/utils'
import { APIClient } from '@/services'
import { AppStatus } from '@/redux'

function Tags({ dispute, getStyles }) {
  const { isMediator } = APIClient.Session.useSession()
  const { refresh } = APIClient.Disputes.disputesManager.useRetrieve({ id: dispute.id })

  const onUpdateStatus = async (status: Dispute['status']) => {
    if (status !== dispute?.status) {
      AppStatus.set('loading')
      const data = {
        ...dispute,
        status,
        category: TypeGuards.isString(dispute?.category) ? dispute?.category : dispute?.category?.type,
      }
      try {
        await APIClient.Disputes.disputesManager.options.updateItem(data)
        await refresh()
      } catch (e) {
        console.error('Error updating dispute', e, 'Dispute Details')
      }
      AppStatus.set('done')
    }
  }

  const statusOptions = DisputeUtils.availableStatus.filter(item => item.value !== 'pending')

  return (
    <View css={getStyles('tagsWrapper')}>
      {isMediator ? (
        <DisputeSelectStatus
          options={statusOptions}
          value={dispute?.status}
          onValueChange={onUpdateStatus}
          variants={['details']}
          showBadge
          debugName='Select category'
        />
      ) : (
        <DisputeStatusTag status={dispute.status} styles={getStyles('statusTag')} />
      )}

      <DisputeCategoryTag category={dispute.category} styles={getStyles('categoryTag')} />
      {!!dispute.price ? (
        <Tag leftIcon='pound' text={formatPrice(dispute.price)} debugName='price tag' styles={getStyles('priceTag')} />
      ) : null}
    </View>
  )
}

function getTabs(path: string, id: Dispute['id']) {
  return [
    {
      text: 'Case details',
      onPress: () => Navigation.goToDispute({ id, path: 'details' }),
      selected: path === 'case_details',
    },
    {
      text: 'Settlements',
      onPress: () => Navigation.goToDispute({ id, path: 'settlements' }),
      selected: path === 'settlements',
    },
    {
      text: 'Participants',
      onPress: () => Navigation.goToDispute({ id, path: 'participants' }),
      selected: path === 'participants',
    },
  ]
}
type TabType = ReturnType<typeof getTabs>[0]

type DisputeInfoProps = ComponentVariants<typeof DisputeInfoStyles> & {
  styles?: Partial<typeof DisputeInfoStyles>
  style?: StyleSheet
  dispute: Dispute
  threads: DisputeThread[]
}

export function DisputeInfo(props: DisputeInfoProps) {
  const { dispute, threads, variants, responsiveVariants, styles, style, ...rest } = props
  const { showSignButton, NavigateToSignButton, allIsSigned, DownloadFinalVersionButton } = useSettlements({ dispute })

  const variantStyles = useDefaultComponentStyle<'u:DisputeInfo', typeof DisputeInfoStyles>('u:DisputeInfo', {
    variants,
    styles,
    responsiveVariants,
  })

  const { getStyles } = useGetStyles(variantStyles)

  const { thread = null } = Navigation.getSearchParams()
  const path = window?.location.pathname.split('/').reverse().find(Boolean)
  const tabsButtons = useMemo(() => getTabs(path, dispute.id), [path, dispute.id])

  const renderThread = useCallback((item: DisputeThread, index: number, array: DisputeThread[]) => (
    <Thread
      thread={item}
      dispute={dispute}
      key={item.id}
      selected={thread === String(item.id)}
      onPress={() => Navigation.goToDispute({ id: dispute.id, path: 'chat', params: { thread: String(item.id) }})}
      debugName={`Thread DisputeInfo ${index}`}
      {...MiscUtils.getPositionProps(index, array.length)}
    />
  ), [thread])

  const renderTabButton = useCallback((item: TabType, index: number, array: TabType[]) => (
    <Button
      {...item}
      key={index + item.text}
      styles={getStyles('button', [item.selected && 'selected'])}
      rightIcon='chevron-right'
      debugName={`Pressed ${item.text}`}
      {...MiscUtils.getPositionProps(index, array.length)}
    />
  ), [tabsButtons])

  return (
    <View css={[getStyles('wrapper'), style]} {...rest}>
      <View>
        <Text style={getStyles('disputeNumber')} text={`#${dispute.id}`} />
        {'\u00A0'}
        <Text style={getStyles('disputeTitle')} text={dispute.name} />
      </View>
      <Tags dispute={dispute} getStyles={getStyles} />
      {showSignButton && <NavigateToSignButton />}
      {allIsSigned && <DownloadFinalVersionButton />}
      <Text variants={['p1']} css={applyTextMaxLines(3, getStyles('description'))} text={dispute.description} />
      <Text variants={['p4']} text={`Dispute created on ${DateUtils.formatCreatedAt(dispute.created_datetime)}`} />
      {tabsButtons.map(renderTabButton)}
      <View css={getStyles('chatWrapper')}>
        <Text css={getStyles('chatTitle')} text='Chat' />
        {threads?.map(renderThread)}
      </View>
    </View>
  )
}

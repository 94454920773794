import { Text, View } from '@/components'
import { useGetStyles } from '@/utils'
import { DropzoneFile, ProgressCircle } from '@codeleap/web'
import { onUpdate } from '@codeleap/common'
import { useMemo, useState } from 'react'
import { UploadingStatusBooleans, UploadSettlementProps } from '.'

const thresholds = [
  { limit: 50000000, value: 1500 },
  { limit: 10000000, value: 1000 },
  { limit: 1000000, value: 500 },
  { limit: 0, value: 150 },
]

type UploadingStatusProps = UploadingStatusBooleans & {
  file: DropzoneFile
  styles: UploadSettlementProps['styles']
}

export function UploadingStatus({ isDone, file, styles }: UploadingStatusProps) {
  const [progress, setProgress] = useState(0)
  const { getStyles } = useGetStyles(styles)

  onUpdate(() => {
    let timer: NodeJS.Timeout
    if (isDone) {
      clearInterval(timer)
      setProgress(100)
    } else {
      timer = setInterval(() => {
        setProgress(prev => (prev < 90 ? prev + 5 : prev))
      }, timestamp ?? 150)
    }
    return () => clearInterval(timer)
  }, [isDone, progress])

  const timestamp = useMemo(() => thresholds.find(threshold => file?.size > threshold.limit).value, [file])

  return (
    <View css={styles.uploadingWrapper}>
      <ProgressCircle progress={progress} size={100} styles={getStyles('uploadingProgress')} showProgress />
      <Text css={styles.uploadingText} text={isDone ? 'Upload completed' : 'Uploading File'} />
      <Text css={styles.uploadingName} text={file?.name ?? 'File.pdf'} />
    </View>
  )
}

import { useMemo, useRef } from 'react'
import { Modal, View, ActionIcon, Text, Button } from '@/components'
import { APIClient } from '@/services'
import { onUpdate, useForm } from '@codeleap/common'
import { DisputeSettlement } from '@/types'
import { AppForms, variantProvider } from '@/app'
import { AppStatus } from '@/redux'
import { Context } from './UploadSettlement/Context'
import { ActionIconProps, ButtonProps } from '@codeleap/web'
import { useIsMobile } from '@/utils'

export type UploadSettlementProps = {
  visible: boolean
  toggle: () => void
  settlement: DisputeSettlement
}

export function Header({ isMobile, ...props }: Pick<ActionIconProps, 'onPress'> & {isMobile: boolean}) {
  return (
    <View variants={['row', 'justifySpaceBetween']}>
      <Text variants={[isMobile ? 'h5' : 'h3']} text={'Edit description'} />
      <ActionIcon
        debugName={'Icon close modal'}
        name={'x'}
        variants={['iconSize:3', 'minimal', 'neutral10']}
        {...props}
      />
    </View>
  )
}

export function Footer(props: Omit<ButtonProps, 'debugName'>) {
  // @ts-ignore
  return <Button text={'Save'} debugName={`Pressed Footer Button`} {...props} />
}

export function EditContext({ settlement, toggle, ...props }: UploadSettlementProps) {
  const isMobile = useIsMobile()
  const prevContext = useRef('')

  const form = useForm(AppForms.uploadFile, { initialState: settlement })
  const { update } = APIClient.Settlements.settlementsManager.useUpdate()

  const { hasError, error } = useMemo(() => {
    const wordCount = form.values.context.length ?? 0

    return {
      wordCount,
      hasError: wordCount > 2000 || wordCount === 0,
      error: wordCount === 0 ? 'This is a required field' : `${wordCount}/2000 characters`,
    }
  }, [form.values.context])

  onUpdate(() => {
    if (settlement) form.setFormValues(settlement)
  }, [settlement])

  const handleUpdate = async () => {
    let timeout = 500
    if (form.values.context !== prevContext.current) {
      AppStatus.set('loading')
      timeout = 100
      await update(form.values)
    }
    toggle()
    setTimeout(() => AppStatus.set('done'), timeout)
  }

  const gap = useMemo(() => isMobile ? 2 : 4, [isMobile])

  return (
    <Modal
      {...props}
      showClose={false}
      dismissOnBackdrop={false}
      closeOnEscape={false}
      toogle={toggle}
      variants={[isMobile ? '' : 'centered']}
      styles={{ innerWrapper: styles.innerWrapper }}
      css={styles.wrapper}
    >
      <View variants={[`gap:${gap}`, 'column']}>
        <Header onPress={toggle} isMobile={isMobile} />
        <Context form={form} hasError={hasError} error={error} height={isMobile ? '100%' : '50vh'} />
        <Footer onPress={handleUpdate} disabled={hasError} />
      </View>
    </Modal>
  )
}

const styles = variantProvider.createComponentStyle(
  theme => ({
    wrapper: {
      maxWidth: 800,
      ...theme.presets.fullWidth,
      [theme.media.down('small')]: {
        ...theme.spacing.padding(2),
        ...theme.spacing.margin('auto'),
      },
    },
    innerWrapper: {
      [theme.media.down('small')]: {
        ...theme.spacing.padding(2),
      },
    },
  }),
  true,
)

import { Button } from '@/components'
import { useGetStyles } from '@/utils'
import { ButtonProps } from '@codeleap/web'

type FooterProps = Omit<ButtonProps, 'debugName'> & {
  isDone: boolean
  isUploading: boolean
  hasFile: boolean
}

export function Footer({ isDone, isUploading, hasFile, styles, ...props }: FooterProps) {
  const { getStyles } = useGetStyles(styles)

  return (
    // @ts-ignore
    <Button
      styles={getStyles('footerButton')}
      text={hasFile ? 'Add file' : 'Choose' }
      debugName={`Pressed Footer Button`}
      {...props}
    />
  )
}

import { I18N } from '@/app/i18n'
import { createForm, yup } from '@codeleap/common'

export const uploadFile = createForm('uploadFile', {
  context: {
    type: 'text',
    required: true,
    label: I18N.t('UploadFiles.description.label'),
    placeholder: I18N.t('UploadFiles.description.placeholder'),
    multiline: true,
    validate: yup.string().required(),
  },
  id: { type: 'number' },
})

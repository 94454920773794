import { api } from '@/app'
import { DisputeMessage, DisputeThread } from '@/types'
import { PaginationResponse, QueryManager, useMemo, useState } from '@codeleap/common'
import { queryClient } from '../queryClient'
import { useFlatlistProps } from '@/utils'

const BASE_URL = 'crm/dispute_messages/'

export const messagesManager = new QueryManager({
  itemType: {} as DisputeMessage,
  name: 'Disputes Messages',
  queryClient: queryClient.client,

  listItems: async (limit, offset, filters) => {
    const response = await api.get<PaginationResponse<DisputeMessage>>(BASE_URL, {
      params: {
        limit: 20,
        offset,
        ...filters,
      },
    })
    return response.data
  },
  createItem: async data => {
    const response = await api.post(BASE_URL, data)
    return response.data
  },
  actions: {
    async markRead(manager, threadId: DisputeMessage['thread']) {
      await api.patch('crm/read_dispute_messages/', {
        thread: threadId,
      })
    },
  },
})

export function useMessages(id: DisputeThread['id']) {
  const [newMessages, setNewMessages] = useState<DisputeMessage[]>([])
  const messages = messagesManager.use({
    filter: {
      thread: id,
    },
  })

  const messagesListProps = useFlatlistProps(messages)

  const messagesArray = useMemo(() => {
    const allMessages = [...Object.values(messages.itemMap), ...newMessages]
    const seenIds = new Set<number>()

    messagesManager.actions.markRead(id)

    return allMessages
      .filter((m) => m.thread === id && !seenIds.has(Number(m.id)) && seenIds.add(Number(m.id)))
      .sort((a, b) => new Date(a.created_datetime).getTime() - new Date(b.created_datetime).getTime())
  }, [messages.items, newMessages, id])

  function loadMoreMessages() {
    if (!messagesListProps.isFetchingNextPage && messagesListProps.hasNextPage) {
      messagesListProps.fetchNextPage()
    }
  }

  async function loadNewMessages(...msgs: DisputeMessage[]) {
    const lastMessage = messagesArray[0]

    const _newMessages = await api.get<PaginationResponse<DisputeMessage>>(BASE_URL, {
      params: {
        limit: 20,
        thread: id,
        time: lastMessage ? new Date(lastMessage?.created_datetime).toISOString() : null,
      },
    })

    if (_newMessages.data.results.length > 0) {
      setNewMessages([...msgs, ..._newMessages.data.results])
    }
  }

  return {
    messagesArray,
    messages,
    loadMoreMessages,
    loadNewMessages,
  }
}

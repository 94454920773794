export const imageIcons = {
  'apple': require('./apple.svg'),
  'archive': require('./archive.svg'),
  'bell': require('./bell.svg'),
  'bug': require('./bug.svg'),
  'camera': require('./camera.svg'),
  'check': require('./check.svg'),
  'chevron-down': require('./chevron-down.svg'),
  'chevron-left': require('./chevron-left.svg'),
  'chevron-right': require('./chevron-right.svg'),
  'chevron-up': require('./chevron-up.svg'),
  'clock': require('./clock.svg'),
  'cloud-lightning': require('./cloud-lightning.svg'),
  'cloud': require('./cloud.svg'),
  'contact': require('./contact.svg'),
  'contrast': require('./contrast.svg'),
  'edit-2': require('./edit-2.svg'),
  'edit': require('./edit.svg'),
  'eye-off': require('./eye-off.svg'),
  'eye': require('./eye.svg'),
  'facebook': require('./facebook.svg'),
  'file-text': require('./file-text.svg'),
  'file': require('./file.svg'),
  'fingerprint': require('./fingerprint.svg'),
  'folder': require('./folder.svg'),
  'google': require('./google.svg'),
  'heart': require('./heart.svg'),
  'image': require('./image.svg'),
  'info': require('./info.svg'),
  'key': require('./key.svg'),
  'lamp-floor': require('./lamp-floor.svg'),
  'languages': require('./languages.svg'),
  'layers': require('./layers.svg'),
  'leaf': require('./leaf.svg'),
  'loader-2': require('./loader-2.svg'),
  'linkedin': require('./linkedin.svg'),
  'loader': require('./loader.svg'),
  'log-out': require('./log-out.svg'),
  'mail': require('./mail.svg'),
  'map-pin': require('./map-pin.svg'),
  'menu': require('./menu.svg'),
  'message-circle': require('./message-circle.svg'),
  'mic': require('./mic.svg'),
  'minus': require('./minus.svg'),
  'more-vertical': require('./more-vertical.svg'),
  'placeholderNoItems-select': require('./placeholderNoItems_select.png'),
  'placeholder-select': require('./placeholder_select.png'),
  'plus': require('./plus.svg'),
  'search': require('./search.svg'),
  'settings': require('./settings.svg'),
  'star': require('./star.svg'),
  'trash': require('./trash.svg'),
  'user': require('./user.svg'),
  'wifi-off': require('./wifi-off.svg'),
  'wifi': require('./wifi.svg'),
  'x': require('./x.svg'),
  'heart-filled': require('./heart-filled.svg'),
  'bookmark': require('./bookmark.svg'),
  'bookmark-filled': require('./bookmark-filled.svg'),
  'file-plus': require('./file-plus.svg'),
  'briefcase': require('./briefcase.svg'),
  'funnel': require('./funnel.svg'),
  'download': require('./download.svg'),
  'chat': require('./chat.svg'),
  'group': require('./group.svg'),
  'copy': require('./copy.svg'),
  'more-horizontal': require('./more-horizontal.svg'),
  'bag': require('./bag.svg'),
  'send': require('./send.svg'),
  'tail': require('./tail.svg'),
  'tail-left': require('./tail-left.svg'),
  'tail-right': require('./tail-right.svg'),
  'pound': require('./pound.svg'),
  'clip': require('./clip.svg'),
  'invite': require('./invite.svg'),
  'upload': require('./upload.svg'),
  'file-plus_': require('./file-plus_.svg'),
  'arrow-right': require('./arrow-right.svg'),
  'calendar-clock': require('./calendar-clock.svg'),
  'coins': require('./coins.svg'),
  'mouse-pointer-click': require('./mouse-pointer-click.svg'),
  'rocking-chair': require('./rocking-chair.svg'),
  'scale': require('./scale.svg'),
  'timer': require('./timer.svg'),
  'instagram': require('./instagram.svg'),
  'phone': require('./phone.svg'),
  'pen-tool': require('./pen-tool.svg'),
  'clipboard-check': require('./clipboard-check.svg'),
  'file-down': require('./file-down.svg'),
  'lock': require('./lock.svg'),
  'x-circle': require('./x-circle.svg'),
  'x-octagon': require('./x-octagon.svg'),
  'stars': require('./stars.svg'),
}

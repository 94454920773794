import React from 'react'

export function useScrollRef() {
  const scrollRef = React.useRef<HTMLDivElement | null>(null)

  function handleScrollToTop(callback: () => void, threshold = 100) {
    if (scrollRef.current && scrollRef.current.scrollTop <= threshold) {
      callback()
    }
  }

  function scrollToBottom() {
    scrollRef.current.scrollTo({
      top: scrollRef.current.scrollHeight,
      behavior: 'smooth',
    })
  }

  return { scrollRef, handleScrollToTop, scrollToBottom }
}

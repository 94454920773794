import React, { useCallback } from 'react'
import { variantProvider } from '@/app'
import { Alert, Button, Modal, View } from '@/components'
import { AppStatus } from '@/redux'
import { APIClient } from '@/services'
import { Navigation, useParticipantList, useSettlements } from '@/utils'
import { useBooleanToggle } from '@codeleap/common'

export const SignaturesRequestedModal = ({ visible, toggle, dispute, settlement, onSuccess }) => {
  const { DownloadFinalVersionButton, allIsSigned } = useSettlements({ dispute })
  const { FirstPartyParticipants, SecondPartyParticipants } = useParticipantList(dispute, {
    showCopyEmailAction: true,
    showOnlySignatories: true,
  })

  const [visibleAlert, toggleAlert] = useBooleanToggle(false)

  async function onRevoke() {
    AppStatus.set('loading')
    try {
      toggle()
      await APIClient.Settlements.revokeSign(settlement.id)
      onSuccess?.()

      AppStatus.set('done')
      Navigation.goToDispute({ id: dispute.id })
    } catch (e) {
      AppStatus.set('idle')

      Navigation.navigate('Disputes.List', {})
      console.error(e)
    }
  }

  const getIsSigned = useCallback((party) => {
    const isSigned = settlement?.signatures?.find((s) => s.party === party)?.signed
    return isSigned
  }, [settlement])

  const getProps = useCallback((party) => {
    const isSigned = getIsSigned(party)

    return {
      tagText: isSigned ? 'Signed' : 'Pending',
      variants: ['signaturesRequested'],
      styles: { tagWrapper: isSigned ? styles.tagSigned : styles.tagPending },
    }
  }, [settlement])

  return (
    <>
      <Modal
        visible={visible}
        toggle={toggle}
        variants={['centered', 'participant-information']}
        title='Signatures request'
        css={styles.modal}
      >
        <View variants={['marginTop:4', 'column']}>
          <FirstPartyParticipants {...getProps('first_party')} />
          <SecondPartyParticipants {...getProps('second_party')} />
        </View>
        <View
          variants={['gap:2', 'fullWidth', 'justifyCenter', 'marginTop:4']}
          responsiveVariants={{
            small: ['column'],
          }}
        >
          <DownloadFinalVersionButton
            disabled={!allIsSigned}
            icon='download'
            variants={'outline'}
            text='Download PDF'
            css={styles.actionButtons}
          />
          <Button
            css={styles.actionButtons}
            variants={['destructive:outline']}
            text='Revoke sign'
            debugName='Revoke sign'
            onPress={toggleAlert}
          />
        </View>
      </Modal>
      <Alert
        title='Revoke signature'
        body='By revoking the signature, this document will only be available if a new request is made. Are you sure you want to continue with this action?'
        debugName='alert modal'
        type='destructive'
        variants={['revokeSignature']}
        visible={visibleAlert}
        toggle={toggleAlert}
        onAction={onRevoke}
        dismissButtonProps={{ debugName: 'dismiss alert button', text: 'Cancel' }}
        actionButtonProps={{ debugName: 'action alert button', text: 'Revoke' }}
        showClose
      />
    </>
  )
}

const MODAL_WIDTH = 800

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    modal: {
      width: MODAL_WIDTH,
      ...theme.spacing.padding(4),
      ...theme.spacing.margin(2),

      [theme.media.down('small')]: {
        ...theme.presets.fullWidth,
        ...theme.spacing.padding(2),
      },
    },

    tagSigned: {
      width: 80,
      ...theme.presets.center,
      backgroundColor: theme.colors.primary2,
    },
    tagPending: {
      width: 80,
      ...theme.presets.center,
      backgroundColor: theme.colors.neutral2,
    },

    actionButtons: {
      width: 320,

      [theme.media.down('small')]: {
        ...theme.presets.fullWidth,
      },
    },
    downloadIcon: {
      color: theme.colors.neutral7,
    },
  }),
  true,
)

import { assignTextStyle, variantProvider, AppForms, React } from '@/app'
import { Text, TextInput, View } from '@/components'
import { AnyFunction, onUpdate, useForm, useMemo, useState, TypeGuards } from '@codeleap/common'
import { TextInputProps } from '@codeleap/web'

type ToolBarProps = Omit<TextInputProps, 'id' | 'debugName'> & {
  onSend: AnyFunction
  aiMessage?: string
  hasAiMessage?:boolean
}

export function ToolBar({ onSend, aiMessage = '', hasAiMessage = false, ...props }: ToolBarProps) {
  const form = useForm(AppForms.chat, { initialState: { message: aiMessage }})
  const [hasFocused, setHasFocused] = useState(false)

  const isAiMessageVisible = !hasFocused && hasAiMessage

  onUpdate(() => {
    if (TypeGuards.isString(aiMessage) && aiMessage.length > 0) {
      form.setFieldValue('message', aiMessage)
      setHasFocused(false)
    }
  }, [aiMessage])

  async function handleSend() {
    const _message = form.values.message
    form.setFieldValue('message', '')
    onSend(_message)
  }

  async function handleKeyDown(e) {
    if (e.key === 'Enter') {
      if (e.ctrlKey) {
        form.setFieldValue('message', form.values.message + '\n')
        return
      }
      e.preventDefault()
      await handleSend()
    }
  }

  const [leftIcon, rightIcon] = useMemo(() => [
    isAiMessageVisible ? { name: 'stars', styles: { icon: styles.aiIcon, touchableWrapper: styles.aiTouchable }} : {},
    { name: 'send', onPress: handleSend, variants: ['minimal'], disabled: !form.values.message?.trim() || !hasFocused },
  ], [isAiMessageVisible, form.values.message, hasFocused])

  return (
    <View css={styles.wrapper}>
      <TextInput
        {...props}
        {...form.register('message')}
        debugName='Chat TextInput'
        placeholder='Send a message'
        onKeyDown={handleKeyDown}
        multiline
        onFocus={() => !hasFocused ? setHasFocused(true) : null}
        leftIcon={leftIcon as TextInputProps['leftIcon']}
        rightIcon={rightIcon as TextInputProps['rightIcon']}
        variants={['noError', 'toolBar', 'noLabel', isAiMessageVisible ? 'aiMessage' : '']}
      />
      {hasAiMessage ? <Text css={styles.aiText} text='Written by AI. Edit the message before send it.' /> : null}
    </View>
  )
}

const styles = variantProvider.createComponentStyle(theme => ({
  wrapper: {
    ...theme.presets.column,
    ...theme.spacing.gap(1),
    zIndex: 10,
    ...theme.spacing.paddingTop(0.25),
    backgroundColor: theme.colors.neutral1,
    ...theme.presets.bottom,
    ...theme.presets.relative,
    ...theme.presets.fullWidth,
  },
  aiText: {
    ...assignTextStyle('p4')(theme).text,
    lineHeight: '16px',
  },
  aiIcon: {
    color: theme.colors.primary4,
  },
  aiTouchable: {
    width: 'unset',
    height: 'unset',
    backgroundColor: 'unset',
    ...theme.spacing.marginRight(1),
  },
}), true)

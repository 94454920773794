import { ActionIconComposition, ButtonComposition, IconComposition } from '@codeleap/web'
import { variantProvider } from '../theme'
import { AvatarComposition } from './Avatar'
import { assignTextStyle } from './Text'

type States =
  | ''
  | ':owner'
  | ':nextMessageOwner'
  | ':prevMessageOwner'
  | ':left'
  | ':right'
  | ':deletedMessage'

type SettlementComposition =
  | 'wrapper'
  | 'title'
  | 'text'
  | 'icon'
  | 'textWrapper'
  | 'innerWrapper'
  | `sign${Capitalize<ButtonComposition>}`
  | 'outerWrapper'
  | 'textSigned'

export type ChatMessageComposition =
  | 'wrapper'
  | 'time'
  | `innerWrapper${States}`
  | `avatar${States}`
  | `avatar${Capitalize<AvatarComposition>}`
  | 'separator'
  | `text${States}`
  | 'contentWrapper'
  | 'textShowOwner'
  | `tail${States}`
  | `tail${Capitalize<IconComposition>}`
  | `settlement${Capitalize<SettlementComposition>}${States}`
  | `settlementActionIcon${Capitalize<ActionIconComposition>}`

const createChatMessageStyle =
  variantProvider.createVariantFactory<ChatMessageComposition>()

const AVATAR_SIZE = 32

export const ChatMessageStyles = {
  default: createChatMessageStyle((theme) => ({
    wrapper: {
      ...theme.presets.column,
    },
    time: {
      ...assignTextStyle('p4')(theme).text,
      ...theme.spacing.paddingVertical(0.5),
      alignSelf: 'center',
    },
    innerWrapper: {
      ...theme.presets.flex,
      ...theme.presets.fullWidth,
      ...theme.presets.alignEnd,
      height: 'fit-content',
    },
    avatar: {
      alignSelf: 'end',
      minWidth: AVATAR_SIZE,
    },
    avatarWrapper: {
      height: theme.values.itemHeight.small,
      width: theme.values.itemHeight.small,
    },
    avatarImage: {
      height: theme.values.itemHeight.small,
      width: theme.values.itemHeight.small,
    },
    'avatar:owner': {
      display: 'none',
    },
    separator: {
      minWidth: AVATAR_SIZE,
      width: AVATAR_SIZE,
      height: AVATAR_SIZE,
    },
    contentWrapper: {
      ...theme.spacing.margin(0.5),
      ...theme.spacing.marginLeft(1),
      borderRadius: theme.borderRadius.small,
      backgroundColor: '#E5F5F2',
      ...theme.spacing.padding(1),
      maxWidth: window.innerWidth - 76,
      position: 'relative',
    },
    'contentWrapper:owner': {
      backgroundColor: theme.colors.primary2,
      marginLeft: 'auto',
    },
    'contentWrapper:deletedMessage': {
      backgroundColor: theme.colors.neutral3,
    },
    text: {
      ...assignTextStyle('p1')(theme).text,
      color: theme.colors.neutral9,
      height: 'fit-content',
      ...theme.presets.row,
      gap: theme.values.gap,
      display: 'inline',
      maxWidth: 400,
      wordBreak: 'break-word',
      whiteSpace: 'pre-wrap',
    },
    'text:owner': {
      maxWidth: 300,
    },
    'text:deletedMessage': {
      fontStyle: 'italic',
      color: theme.colors.neutral8,
      ...theme.presets.textCenter,
    },
    textShowOwner: {
      ...assignTextStyle('h5')(theme).text,
    },
    tail: {
      ...theme.sized(2),
      position: 'absolute',
    },
    'tail:left': {
      bottom: -2,
      left: -6,
    },
    'tail:right': {
      bottom: -3,
      right: -6,
    },
    'tail:nextMessageOwner': {
      display: 'none',
    },
    'tail:deletedMessage': {
      display: 'none',
    },

    settlementWrapper: {
      ...theme.presets.column,
      ...theme.spacing.gap(1),
    },
    settlementInnerWrapper: {
      ...theme.presets.column,
      backgroundColor: theme.colors.neutral1,
      ...theme.spacing.padding(1),
      ...theme.spacing.gap(1),

    },
    settlementOuterWrapper: {
      ...theme.presets.flex,
      ...theme.spacing.gap(1),
      width: 260,
      ...theme.presets.center,
    },
    settlementTextWrapper: {
      ...theme.presets.flex,
      ...theme.presets.column,
      ...theme.spacing.gap(0.5),
      ...theme.presets.fullWidth,
    },
    settlementIcon: {
      ...theme.sized(2),
      color: theme.colors.neutral7,
      minWidth: theme.sized(2).width,
    },
    settlementTitle: {
      ...assignTextStyle('p2')(theme).text,
      lineHeight: '16px',
      color: theme.colors.neutral8,
      width: 'calc(260px - 64px)',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    settlementText: {
      ...assignTextStyle('p5')(theme).text,
      lineHeight: '12px',
      color: theme.colors.neutral7,
    },
    settlementActionIconIcon: {
      color: theme.colors.primary3,
    },
    settlementSignWrapper: {
      borderRadius: theme.borderRadius.small,
    },
    settlementTextSigned: {
      color: theme.colors.neutral9,
    },
  })),
}

import { Profile } from '@/types'
import { View, Text, ParticipantItem, ParticipantItemProps } from '@/components'
import { MiscUtils } from '@/utils'
import { useCallback } from 'react'
import { variantProvider } from '@/app'

type ParticipantsListProps = Omit<ParticipantItemProps, 'debugName'> & {
  participants: Profile[]
  title: string
  participantItemProps?: (participant: Profile) => ParticipantItemProps
}

export function ParticipantsList({ participants, title, participantItemProps, ...props }: ParticipantsListProps) {
  const renderItem = useCallback((participant: Profile, index: number, array: Profile[]) => {
    const participantProps = participantItemProps?.(participant)
    const positionProps = MiscUtils.getPositionProps(index, array.length)

    return (
      <ParticipantItem
        key={`ParticipantItem:${participant?.id ?? 'id'}-${index}`}
        debugName={`ParticipantItem:${participant?.id ?? 'id'}`}
        participant={participant}
        {...participantProps}
        {...positionProps}
        {...props}
        variants={[...(participantProps?.variants ?? [] as any), ...(props.variants ?? [])]}
      />
    )
  }, [props])

  if (!participants.length) return null
  return (
    <View variants={['column']}>
      <Text text={title} variants={['marginBottom:1', 'p2']} />
      <View css={styles.innerWrapper}>
        {participants?.map(renderItem)}
      </View>
    </View>
  )
}

const styles = variantProvider.createComponentStyle(theme => ({
  innerWrapper: {
    ...theme.spacing.marginBottom(2),
    ...theme.presets.column,
    backgroundColor: 'none',
  },
}), true)
